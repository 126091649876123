<template>
  <TheTools class="tools-calendar">
    <template slot="nav-left">
      <TheToolsButton :text="'Search'" :icon="'search'" :type="'trigger'" @click="handleSearch" v-if="isDesktop" :active="current === 'search'" />
      <TheToolsButton :text="'Filters'" :icon="'filters'" :type="'trigger'" @click="handleFilters" v-if="isDesktop" :active="current === 'filters'" />
      <TheToolsButton :text="'Legend'" :icon="'info'" :type="'trigger'" @click="handleLegend" v-if="isDesktop" :active="current === 'legend'" />
    </template>
    <template slot="nav-right">
      <TheToolsViews :current-view="currentView" v-if="isDesktop" />
    </template>
    <template slot="content">
      <TheToolsDropdown :type="'search'" :trigger="searchTrigger" :force-active="current === 'search'" />
      <TheToolsDropdown :type="'filters'" :trigger="filtersTrigger" :force-active="current === 'filters'" />
      <TheToolsDropdown :type="'legend'" :trigger="legendTrigger" :force-active="current === 'legend'" />
      <TheToolsDropdown :type="'views'" :trigger="viewsTrigger" :force-active="current === 'views'" :current-view="currentView" v-if="!isDesktop" />
    </template>
  </TheTools>
</template>

<script>
import { mapGetters } from 'vuex'
import TheTools from './TheTools'
import TheToolsButton from './TheToolsButton'
import TheToolsDropdown from './TheToolsDropdown'
import TheToolsViews from './TheToolsViews'

export default {
  name: 'TheToolsCalendar',
  components: {
    TheTools,
    TheToolsButton,
    TheToolsDropdown,
    TheToolsViews
  },
  data () {
    return {
      searchTrigger: {
        text: 'Search',
        icon: 'search'
      },
      filtersTrigger: {
        text: 'Filters',
        icon: 'filters'
      },
      legendTrigger: {
        text: 'Legend',
        icon: 'info'
      },
      viewsTrigger: {
        text: 'Change View',
        icon: 'calendar'
      },
      current: '',
      currentView: ''
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice',
      selected: 'users/getSelected'
    }),
    isDesktop () {
      return this.device === 'desktop'
    },
    selectedLength () {
      return this.selected.length
    }
  },
  watch: {
    '$route' () {
      this.currentView = this.$route.name
    }
  },
  created () {
    this.currentView = this.$route.name
  },
  methods: {
    handleSearch (status) {
      this.current = status ? 'search' : ''
    },
    handleFilters (status) {
      this.current = status ? 'filters' : ''
    },
    handleLegend (status) {
      this.current = status ? 'legend' : ''
    }
  }
}
</script>

<style lang="scss">
.tools-calendar{
  .filters-category{
    @include media("small"){
      width: 100%;
    }
    @include media("medium-large"){
      width: calc(50% - #{layout("spacing")});
    }
  }
}
</style>
