<template>
  <div class="calendar-default">
    <div class="calendar-default__date-select">
      <div class="calendar-default__select">
        <select name="months" id="months" ref="months" v-model="months" @change="selectChangeHandle">
          <option
            :key="month.toLowerCase()"
            :value="month.toLowerCase()"
            v-for="month in monthsArray">
            {{ month }}
          </option>
        </select>
        <span></span>
      </div>
      <div class="calendar-default__select">
        <select name="years" id="years" ref="years" v-model="years"  @change="selectChangeHandle">
          <option
            :value="year"
            :key="year"
            v-for="year in content.years">
            {{ year }}
          </option>
        </select>
        <span></span>
      </div>
    </div>

    <CalendarTooltip
      :active="tooltipActive"
      :style="tooltipStyle"
      :content="tooltip"
      ref="tooltip"
      position="'top'" />

    <full-calendar
      :events="events"
      :config="config"
      :event-sources="eventSources"
      ref="calendar" />
  </div>
</template>

<script>
import '@vendors/fullcalendarYV/dist/fullcalendar.css'
import { getPosition, getSize } from '@modules/dom'
import CalendarTooltip from './CalendarTooltip'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CalendarDefault',
  components: {
    CalendarTooltip
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      date: '',
      events: {},
      months: '',
      years: '',
      tooltip: {
        title: '',
        duration: '',
        period: '',
        location: '',
        members: ''
      },
      tooltipStyle: '',
      tooltipActive: false,
      eventSources: [
        {
          events: (start, end, timezone, callback) => {
            this.$nextTick(() => {
              if (this.$refs.calendar) {
                this.updateStartDate(start.toDate())
                this.updateEndDate(end.toDate())
                this.fetchGetCalendarEvents(this.activeFilters).then(() => {
                  this.events = this.getEvents()
                })
              }
            })
          }
        }
      ],
      config: {
        defaultView: 'month',
        weekends: false,
        editable: false,
        header: {
          left: 'prev,today,next',
          right: 'year,month,agendaWeek,agendaDay'
        },
        eventMouseover: (calEvent, jsEvent, view) => {
          this.setTooltip(calEvent, jsEvent.target, view)
        },
        eventMouseout: (calEvent, jsEvent, view) => {
          this.tooltipActive = false
        },
        eventClick: (event, jsEvent, view) => {
          if (event.clickable) this.$router.push({ path: `/calendar/event/${event.guid}` })
          if (event.link) window.open(event.link, '_blank')
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      activeFilters: 'filters/getActiveFilters'
    }),
    monthsArray () {
      return Object.values(this.content.months)
    }
  },
  watch: {
    content () {
      this.events = this.getEvents()
    },
    'events' () {
      this.$nextTick(() => {
        if (this.$refs.calendar) {
          this.$refs.calendar.fireMethod('rerenderEvents')
        }
      })
    }
  },
  mounted () {
    this.events = this.getEvents()
    this.date = this.getDate()
    this.months = this.monthsArray[this.date.month].toLowerCase()
    this.years = this.date.year
  },
  methods: {
    ...mapActions({
      fetchGetCalendarEvents: 'page/fetchGetCalendarEvents',
      updateStartDate: 'filters/updateStartDate',
      updateEndDate: 'filters/updateEndDate'
    }),
    refreshEvents () {
      this.$refs.calendar.$emit('refetch-events')
    },
    format (date, time) {
      return date.trim().split('/').reverse().join('-') + 'T' + time.trim().split(' ')[0]
    },
    getEvents () {
      const events = []
      this.content.items.forEach(item => {
        const { start, end } = item.schedule
        events.push({
          title: item.title,
          start: this.format(start.date, start.time),
          end: this.format(end.date, end.time),
          color: item.type.color,
          location: item.location,
          responsible: item.responsible,
          members: item.members ? item.members : [],
          type: item.type.guid,
          guid: item.guid,
          link: item.url && item.url !== '' ? item.url : null,
          clickable: item.clickable // this.isClickable(item.type.guid)
        })
      })
      return events
    },
    // isClickable (guid) {
    //   const clickable = ['croplife-group-meeting', 'meeting', 'croplife-events']
    //   return clickable.includes(guid)
    // },
    getMembers (members) {
      const membersList = members.map(member => {
        return [...member.firstname].shift().toUpperCase() + [...member.lastname].shift().toUpperCase()
      })
      return membersList.join(', ')
    },
    setTooltip (calEvent, target, view, eventPosition) {
      const elSize = getSize(target)
      const elPos = getPosition(target)
      const { title, location, start, end } = calEvent
      const startDate = start ? `${start.format('DD/MM/YYYY')}` : ''
      const endDate = end ? ` - ${end.format('DD/MM/YYYY')}` : ''
      const durationStart = start ? `${start.format('h:mm A')}` : ''
      const durationEnd = end ? ` - ${end.format('h:mm A')}` : ''
      const left = elPos.left + elSize.width / 2
      const top = elPos.top - 10

      this.$set(this.tooltip, 'title', title)
      this.$set(this.tooltip, 'location', location)
      this.$set(this.tooltip, 'duration', `${durationStart}${durationEnd}`)
      this.$set(this.tooltip, 'period', `${startDate}${endDate}`)
      this.$set(this.tooltip, 'members', this.getMembers(calEvent.members))
      this.$set(this.tooltip, 'responsible', calEvent.responsible)

      this.tooltipStyle = `
        z-index: 10;
        left: ${left}px;
        top: ${top}px;
      `
      this.tooltipActive = true
    },
    getDate () {
      const getDate = this.$refs.calendar.fireMethod('getDate')
      return {
        year: getDate.year(),
        month: getDate.month()
      }
    },
    selectChangeHandle () {
      const months = Object.keys(this.content.months)
      const month = months.indexOf(this.$refs.months.value) + 1 >= 10
        ? months.indexOf(this.$refs.months.value) + 1
        : `0${months.indexOf(this.$refs.months.value) + 1}`
      this.$refs.calendar.fireMethod('gotoDate', `${this.$refs.years.value}-${month}-01`)
    }
  }
}
</script>

<style lang="scss">
  .calendar-default{

    @include media('small-medium') {
      overflow-y: auto;
    }
    // FULL CALENDAR

    .fc {
      min-width: 56rem;

      .fc-toolbar {
        margin-bottom: .5rem;

        .fc-button {
          border:1px solid transparent;
          border-radius: .4rem;
          background: none;
          background-color: colors(primary);
          color: colors('background');
          outline: none;;
          font-size: 1.5rem;

          & + .fc-button {
            margin-left: 0.5rem;
          }

          &.fc-state-default {
            background:none;
            background-color: colors(primary);
            border:1px solid transparent;
            color: colors("background");
            box-shadow: none;

            &.fc-state-active, &.fc-state-hover  {
              background:none;
              background-color: colors('background');
              border:1px solid colors(primary);
              color: colors(primary);
              box-shadow: none;
            }

          }

          &.fc-next-button, &.fc-prev-button  {
            padding: .5rem;
          }
        }
      }

      .fc-view-container {
        .fc-head {
          background-color: colors('topbar');
          color: colors('background');

          .fc-day-header {
            padding: .5rem;
          }
        }
        .fc-content {
          padding: .5rem 1rem;
        }
        .fc-other-month {
          background-color: colors('foreground')
        }
      }
    }

    &__date-select {
      @include media('small-medium') {
        min-width: 56rem;
      }

      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: layout("spacing");
    }

    &__select{
      @include custom-select;
      &:first-of-type {
        margin-right: 1rem;
      }
      select{
        width:auto;
        min-width: 11rem;
        padding: 0.5rem 5rem 0.5rem 1rem;
      }
    }
  }
</style>
