<template>
  <ContainerHub class="hub-calendar">
    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :email="email"
        :colorGuid="colorGuid"
        :description="description"
        :back-btn="showDetail"
      />
      <TheToolsCalendar v-if="!showDetail" />
    </template>
    <template slot="body">
      <router-view />
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters } from 'vuex'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'

import TheToolsCalendar from '@blocks/tools/TheToolsCalendar'

export default {
  name: 'HubCalendar',
  props: {
    name: {
      type: String,
      required: true
    },
    showDetail: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',
      colorGuid: 'page/getColorGuid'
    })
  },
  components: {
    HubHeader,
    ContainerHub,
    TheToolsCalendar
  }
}
</script>

<style lang="scss">
.hub-calendar {
  .hub-header__body {
    @media print {
      display: none;
    }
  }
}
</style>
