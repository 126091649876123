<template>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut">

    <div class="tooltip calendar-tooltip" v-show="active">
      <div v-if="content.title">{{ content.title }}</div>
      <div class="icon-clock" v-if="content.duration">
        <span>{{ content.duration }}</span>
      </div>
      <div class="icon-pin"  v-if="content.location">
        <span v-html="content.location"></span>
      </div>
      <div class="icon-user" v-if="content.responsible">
        <span>{{ content.responsible }}</span>
      </div>
      <div class="icon-user" v-if="content.members">
        <span>{{ content.members }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
import ElementTooltip from '@elements/ElementTooltip'
export default {
  name: 'CalendarTooltip',
  extends: ElementTooltip,
  props: {
    content: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .calendar-tooltip {
    position: absolute;
    z-index: 10;
    display: inline-block;
    min-width: 30rem;
    text-align: left;
    transform: translateY(-100%) translateX(-50%);

    > div{
      display: flex;

      &::before {
        width: 1.5rem;
        margin-right: .7rem;
      }
    }
  }
</style>
