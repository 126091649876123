<template>
  <div class="calendar-view">
    <HubCalendar
      :name="'calendar'"
      :show-detail="showDetail"
      v-if="loaded"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import updateBreadcrumb from '@mixins/updateBreadcrumb'
import HubCalendar from '@blocks/hubs/HubCalendar'

export default {
  name: 'CalendarView',
  mixins: [ updateBreadcrumb ],
  components: {
    HubCalendar
  },
  data () {
    return {
      showDetail: false,
      loaded: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.updateshowDetail(to)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.updateshowDetail(to)
    next()
  },
  methods: {
    ...mapActions({
      fetchGetPage: 'page/fetchGetPage',
      fetchData: 'filters/fetchData'
    }),
    updateshowDetail (to) {
      this.loaded = false
      this.showDetail = !!to.params.eventId
      const service = this.showDetail ? 'calendar-detail' : 'calendar'
      const params = this.showDetail ? { eventId: to.params.eventId } : {}

      this.fetchGetPage({ service, params }).then(() => {
        this.loaded = true
      })
    }
  }
}
</script>

<style lang="scss">
.calendar-view {
  &__legend {
    display: flex;
    margin-top: 3rem;

    .badge {
      margin-left: 1.5rem;
    }
  }
}
</style>
