<template>
  <div class="calendar-default-view">
    <CalendarDefault
      :content="calendarContent" />

    <CalendarLegend
      :items="legendItems"
      :title="$t(`message.calendar.legend`)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import updateBreadcrumb from '@mixins/updateBreadcrumb'
import CalendarDefault from '@blocks/calendar/CalendarDefault'
import CalendarLegend from '@blocks/calendar/CalendarLegend'
import legendItems from '@mixins/legendItems'

export default {
  name: 'CalendarDefaultView',
  components: {
    CalendarDefault,
    CalendarLegend
  },
  mixins: [ updateBreadcrumb, legendItems ],
  computed: {
    ...mapGetters({
      items: 'events/getCalendarItems',
      months: 'events/getMonths',
      range: 'events/getCalendarRange'
    }),
    calendarContent () {
      return {
        items: this.items,
        months: this.months,
        years: this.years,
        colors: this.colors
      }
    },
    years () {
      let year = []
      for (let i = 0; i <= (this.range.max - this.range.min); i++) {
        year.push(this.range.min + i)
      }
      return year
    }
  },
  methods: {
    ...mapActions('filters', [
      'updateStartDate',
      'updateEndDate'
    ])
  }
}
</script>
<style lang="scss">

</style>
